<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Klienta galvenā kontaktpersona
      </h3>
    </template>
    <template v-slot:buttons>
      <template v-if="customer.contacts.length > 1 && this.$userCan('manage_customers')">
        <Button icon="pencil" @click="showForm('EditCustomerDetailContact')" />
      </template>
    </template>
    <template v-slot:content>

      <template v-if="customer.main_contact">
        <template v-if="!displayForm('EditCustomerDetailContact')">
          <ShowCustomerDetailContact :customer="customer" />
        </template>
        <template v-else>
          <EditCustomerDetailContact :customer="customer" />
        </template>
      </template>

      <template v-else>
        <template v-if="!displayForm('AddPaymentDetails')">
          <div class="w-full">
            <p class="text-center text-lg text-gray-400 mb-3">Klientam nav norādīta galvenā kontaktpersonas</p>
            <button @click="showForm('AddPaymentDetails')" type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out">
              Pievienot
            </button>
          </div>
        </template>

        <template v-else>
          <EditCustomerDetailContact :customer="customer" />
        </template>
      </template>

    </template>
  </item-card>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ShowCustomerDetailContact from "@/components/Customer/CustomerDetails/CustomerDetailContact/ShowCustomerDetailContact"
import EditCustomerDetailContact from "@/components/Customer/CustomerDetails/CustomerDetailContact/EditCustomerDetailContact"

export default {
  name: "CustomerDetailContact",
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  components: {
    ItemCard,
    ShowCustomerDetailContact,
    EditCustomerDetailContact,
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>