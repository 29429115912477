<template>
  <item-text title="Vārds Uzvārds" :text="customer.main_contact.name" />
  <template v-if="customer.type.id === 1">
    <item-text title="Tel. nr." :text="customer.main_contact.phone ? customer.main_contact.phone : 'Nav norādīts'" />
    <item-text title="E-pasts" :text="customer.main_contact.email ? customer.main_contact.email : 'Nav norādīts'" />
  </template>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowCustomerDetailContact",
  props: {
    customer: {
      type: Object,
      required: true,
    }
  },
  components: {
    ItemText,
  },
}
</script>

<style>

</style>